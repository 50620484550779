export default function AACo1824Logo({ classNames }) {
  return (
    <img
      src="https://res.cloudinary.com/aaco/image/upload/v1708350885/Distributor%20Portal/1824/1824_Logo_olbkrr.png"
      className={classNames}
      loading="priority"
      alt="1824 logo"
    />
  );
}
