import React from 'react';
import PropTypes from 'prop-types';

// @TODO: Make the svg icon can be centered or the icon svg need to have same viewbox and dimension
const Icon = ({ name, className, width, height, alt }) => {
  return (
    <svg
      role="presentation"
      aria-hidden="true"
      className={`aaco-icon ${className}`}
      width={width}
      height={height}
      alt={alt}
    >
      <use xlinkHref={`/assets/icons/app-icons.svg#${name}`}></use>
    </svg>
  );
};

export default Icon;

// @TODO: Need to define required props, in example width, height, alt, etc.
Icon.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  height: PropTypes.number,
  name: PropTypes.string,
  width: PropTypes.number,
};

// @TODO: Need to define default icon (name) if not available on the icon set.
Icon.defaultProps = {
  alt: '',
  className: '',
  height: 24,
  name: 'arrow-right',
  width: 24,
};
