import classnames from 'classnames';

import { isDDEnv } from '../../../utils/helper';

export const ICONS = ['arrow-right', 'hamburger', 'chevron-down', 'cross'];

export const ICON_POSITION = {
  left: 'left',
  right: 'right',
};

export const getIconClassWH = ({ icon, iconPosition, invert, style }) => {
  return classnames(
    {
      'aaco-icon-container inline-flex justify-center items-center w-[40px] h-[40px] rounded-button border-1 border-eucalyptus-dark dd-en:border-light-grey-1':
        Boolean(icon),
      'ml-2.5': icon && iconPosition === ICON_POSITION.right,
      'mr-2.5': icon && iconPosition === ICON_POSITION.left,
      'border-majesty-30 text-violet-bright': Boolean(invert),
    },
    style
  );
};

export const getIconClassDD = ({
  hasIconBorder,
  iconPosition,
  invert,
  style,
}) => {
  let classes = { normal: '', hover: '', position: '' };

  const isRightIcon = iconPosition === ICON_POSITION.right;
  if (hasIconBorder) {
    classes.normal = `
    w-10 h-10 flex items-center justify-center 
    rounded-button border-1 ${
      invert
        ? 'border-40-white-on-dark-blue'
        : 'border-dark-warm-grey dd-en:border-light-grey-1'
    } text-actual-dd-gold`;
    classes.hover = invert
      ? 'hover:border-80-white-on-dark-blue'
      : 'hover:border-light-grey-2';
    classes.position = isRightIcon ? 'ml-2.5' : 'mr-2.5';
  } else {
    classes.normal = 'text-actual-dd-gold';
    classes.hover = 'hover:text-actual-dd-gold';
    classes.position = isRightIcon ? 'ml-1.5' : 'mr-1.5';
  }
  return classnames(...Object.values(classes), style);
};

export const getIconClass = ({
  icon,
  iconPosition,
  invert,
  style,
  hasIconBorder,
}) => {
  if (isDDEnv())
    return getIconClassDD({ icon, iconPosition, invert, style, hasIconBorder });
  return getIconClassWH({ icon, iconPosition, invert, style });
};
